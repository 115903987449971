import { Component } from '@angular/core';
import {PaymentMethod} from '../../shared/shared.service';

@Component({
  selector: 'app-form-print',
  templateUrl: './form-print.component.html',
  styleUrl: './form-print.component.scss'
})
export class FormPrintComponent {
  listPayment: any[] = [];
  data!: any;
  totalPaymentAmount!: number;
  totalPaidAmount!: number;
  totalReceivedAmount!: number;
  listPaymentMethod: any[] = [
    {text: 'cash', value: PaymentMethod.Cash},
    {text: 'transfer', value: PaymentMethod.Transfer},
  ];

  constructor(
  ) {
  }
}
