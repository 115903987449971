<app-filter class="not-print" [title]="'billing.title'" [formConfig]="filterConfig" (onSearchChanges)="getData($event)"></app-filter>
<div class="m-3 bg-white box-child pb-2 not-print">
  <div class="p-3">
<!--    <app-button class="me-2" [iconLeft]="'plus'" [type]="'primary'" [label]="'add' | translate" (clickButton)="add()"></app-button>-->
    <app-button [iconLeft]="'file-down'" [label]="'export' | translate" (clickButton)="export()"></app-button>
  </div>
  <app-table-dynamic [columnsConfig]="tableConfig" [source]="source" (onEdit)="edit($event)" (onView)="view($event)" [total]="totalRows" [actionWidth]="'130px'"
                     [action]="{edit: false, delete: false, cancel: false, refund: false, billing: false,  print: true, editBooking: false}" (onPrint)="onPrint($event)"
                     (onDelete)="delete($event)" [scroll]="{y: 'calc(100vh - 430px)'}" (onChangeSort)="onSort($event)"
                     (onChangePage)="onChangePage($event)" (onChangeSize)="onChangeSize($event)" [page]="filter['page'] ? filter['page'] : 1"></app-table-dynamic>
</div>
<app-form-print #formPrintComponent class="print"></app-form-print>
